.component-input-web {
    margin-bottom: $form-mb;
    .ciw-input-wrap {
        display: flex;
        .ciw-input {
            display: flex;
            flex-shrink: 0;
            justify-content: space-between;
            width: calc(55vw - 162px - 162px);
            @media (max-width: 576px) {
                width: 100%;
            }
            .select-input-web {
                margin-bottom: 5px;
                width: 30%;
                .form-input-wrap {
                    width: 100%;
                    .fiw-input {
                        width: 100%;
                    }
                }
            }
            .text-input-web {
                margin-bottom: 5px;
                width: 30%;
                .form-input-wrap {
                    width: 100%;
                    .fiw-input {
                        width: 100%;
                    }
                }
            }
        }
        .ciw-helper {
            color: #989898;
            padding-left: 182px;
            @media (max-width: 576px) {
                display: none;
            }
        }
        &.price {
            .select-input-web {
                width: 47%;
            }
            .text-input-web {
                width: 47%;
            }
        }
    }
    .ciw-input-action {
        display: flex;
        flex-shrink: 0;
        justify-content: flex-end;
        margin-bottom: 10px;
        width: calc(55vw - 162px - 162px);
        @media (max-width: 576px) {
            width: 100%;
        }
        .mb-button-web .bw-button {
            background: black;
            padding: 10px 30px;
        }
    }
    .ciw-reorder-wrap {
        width: calc(55vw - 162px - 162px);
        @media (max-width: 576px) {
            width: 100%;
        }
    }
}
