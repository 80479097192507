.profile-header-web {
    background: black;
    color: white;
    display: flex;
    justify-content: space-around;
    padding: 33px;
    position: sticky;
    top: 0;
    z-index: 10;
    @media (max-width: 576px) {
        flex-wrap: wrap;
        padding: 10px;
    }
    .phw-link {
        cursor: pointer;
        font-size: 15px;
        font-weight: 500;
        text-transform: uppercase;
        transition: $mb-ease;
        @media (max-width: 576px) {
            padding: 10px;
        }
        &:hover {
            color: $mb-color;
        }
        &.active {
            color: $mb-color;
        }
    }
}
