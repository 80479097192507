.text-input-web {
    margin-bottom: $form-mb;
    .tiw-input {
        background: white;
        border-radius: $form-br;
        border: solid 1px #cccccc;
        color: black;
        &.disabled {
            cursor: not-allowed;
            opacity: 0.4;
        }
    }
}
