.image-helper-web {
    position: relative;
    .mb-image-loader {
        height: 100%;
        left: 0;
        position: absolute;
        right: 0;
        width: 100%;
    }
}
.mb-ihwvh {
    visibility: hidden;
}
.mb-ihwvv {
    visibility: visible;
}
