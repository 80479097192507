.auth-page-web {
    .apw-content {
        display: flex;
        padding: 49px 0;
        @media (max-width: 576px) {
            flex-direction: column;
            padding: 0;
        }
        .apw-left {
            margin: 0 auto;
            padding-right: 12px;
            width: 40%;
            @media (max-width: 576px) {
                width: 100%;
            }
        }
        .apw-right {
            margin: auto;
            padding-left: 12px;
            width: 60%;
            @media (max-width: 576px) {
                width: 100%;
            }
        }
    }
}
