.article-list-web {
    .alw-cover {
        height: calc(100vw / 1920 * 561);
        position: relative;
        width: 100%;
        @media (max-width: 576px) {
            height: 60vh;
        }
        .alw-cover-image {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
        .alw-cover-text {
            align-items: flex-start;
            background-image: linear-gradient(to top, #000000 -24%, rgba(0, 0, 0, 0) 95%);
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: flex-end;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            @media (max-width: 576px) {
                align-items: flex-end;
            }
            .alw-text1 {
                color: white;
                font-size: 60px;
                font-weight: bold;
                margin-bottom: 30px;
                @media (max-width: 576px) {
                    font-size: 30px;
                }
            }
            .alw-text2 {
                color: white;
                font-size: 23px;
                font-weight: bold;
                margin-bottom: 135px;
                @media (max-width: 576px) {
                    font-size: 15px;
                    margin-bottom: 20px;
                }
            }
        }
    }
    .alw-description-wrap {
        display: flex;
        padding: 118px 156px;
        .alw-image {
            height: 82px;
            margin-right: 26px;
        }
        .alw-text {
            font-size: 21px;
            font-weight: bold;
        }
    }
    .alw-infinite {
        display: flex;
        flex-wrap: wrap;
        margin: 0 -18px;
    }
}
