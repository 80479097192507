.mb-header {
    box-shadow: 0 2px 4px -1px rgba(0, 0, 0, 0.06), 0 4px 5px 0 rgba(0, 0, 0, 0.06), 0 1px 10px 0 rgba(0, 0, 0, 0.08);
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: 999;
    .mh-wrap {
        align-items: center;
        background: rgb(255, 255, 255);
        display: flex;
        justify-content: space-between;
        padding: 20px 30px;
        @media (max-width: 820px) {
            padding: 20px;
        }
        .mh-left {
            .mh-logo {
                cursor: pointer;
                height: 40px;
                object-fit: contain;
                width: auto;
            }
        }
        .mh-right {
            align-items: center;
            display: flex;
            .mh-page-wrap {
                align-items: center;
                display: flex;
                @media (max-width: 820px) {
                    align-items: center;
                    background: white;
                    display: none;
                    flex-direction: column;
                    height: 100vh;
                    justify-content: flex-start;
                    left: 0px;
                    overflow-y: auto;
                    padding: 100px 20px;
                    position: fixed;
                    top: 0px;
                    width: 100%;
                    z-index: 1;
                    &.is-open {
                        display: flex;
                    }
                }
                .mh-page-link {
                    cursor: pointer;
                    font-size: 14px;
                    font-weight: bold;
                    margin: 0 10px;
                    text-transform: uppercase;
                    transition: $mb-ease;
                    @media (max-width: 820px) {
                        padding-bottom: 20px;
                        text-align: center;
                    }
                    &.mh-button {
                        background: $mb-color;
                        border-radius: 36px;
                        color: white;
                        padding: 10px 35px;
                        &:hover {
                            color: white;
                            opacity: 0.7;
                        }
                    }
                    &:hover,
                    &.active {
                        color: $mb-color;
                    }
                }
                .mh-dropdown {
                    position: relative;
                    @media (max-width: 820px) {
                        align-items: center;
                        display: flex;
                        flex-direction: column;
                        padding-bottom: 20px;
                    }
                    .mh-dropdown-content {
                        display: none;
                        position: absolute;
                        right: 0;
                        width: auto;
                        z-index: 1;
                        @media (max-width: 820px) {
                            display: block;
                            position: relative;
                        }
                        .mh-dropdown-wrap {
                            background: rgb(255, 255, 255);
                            box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 16px 0px;
                            display: flex;
                            flex-direction: column;
                            padding: 5px 10px;
                            @media (max-width: 820px) {
                                box-shadow: none;
                            }
                            .mh-page-link {
                                padding: 10px 0;
                                white-space: nowrap;
                            }
                        }
                    }
                    &:hover {
                        .mh-dropdown-content {
                            display: block;
                        }
                    }
                }
                .mh-profile-wrap {
                    align-items: center;
                    display: flex;
                    @media (max-width: 820px) {
                        flex-direction: column;
                    }
                    .mh-profile-pic {
                        border-radius: 50%;
                        border: 1px solid $mb-color;
                        cursor: pointer;
                        height: 30px;
                        margin-right: 10px;
                        width: 30px;
                        @media (max-width: 820px) {
                            display: none;
                        }
                    }
                    .mh-page-link {
                        margin-left: 0;
                    }
                }
            }
            .mh-burger {
                display: none;
                max-width: 100%;
                outline: none;
                padding: 0;
                width: auto;
                z-index: 999;
                @media (max-width: 820px) {
                    display: block;
                }
                .bs-hamburger-box {
                    border-radius: inherit;
                    width: 30px;
                    .bs-hamburger-inner {
                        background: rgb(0, 0, 0);
                        height: 2px;
                        width: 30px;
                        &:after {
                            background: inherit;
                            border-radius: inherit;
                            width: inherit;
                            height: inherit;
                        }
                        &:before {
                            background: inherit;
                            border-radius: inherit;
                            width: inherit;
                            height: inherit;
                        }
                    }
                }
                &.is-active {
                    .bs-hamburger-inner {
                        &:after {
                            background: inherit;
                            border-radius: inherit;
                            width: inherit;
                            height: inherit;
                        }
                        &:before {
                            background: inherit;
                            border-radius: inherit;
                            width: inherit;
                            height: inherit;
                        }
                    }
                }
            }
        }
    }
}
