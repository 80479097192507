.intro-property-panel-web {
    padding: 50px 20px 50px 162px;
    @media (max-width: 576px) {
        flex: 1;
        height: 100%;
        padding: 20px;
    }
    .ippw-title {
        color: $mb-color;
        flex-shrink: 0;
        font-size: 40px;
        font-weight: bold;
        margin-bottom: 30px;
        width: calc(55vw - 162px - 162px);
        @media (max-width: 576px) {
            font-size: 22px;
            width: 100%;
        }
    }
    .ippw-wrap {
        display: flex;
        align-items: center;
        .ippw-title-helper {
            color: #989898;
            font-size: 20px;
            font-weight: bold;
            padding-left: 182px;
            @media (max-width: 576px) {
                display: none;
            }
        }
    }
    .ippw-description {
        flex-shrink: 0;
        font-size: 22px;
        font-weight: 500;
        width: calc(55vw - 162px - 162px);
        @media (max-width: 576px) {
            font-size: 16px;
            width: 100%;
        }
    }
}
