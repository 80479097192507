.property-list-big-block-web {
    display: inline-block;
    padding: 20px 18px;
    width: 50%;
    @media (max-width: 576px) {
        width: 100%;
    }
    .plbbw-image-wrap {
        display: block;
        position: relative;
        .plbbw-ratio-helper-web {
            display: block;
            position: relative;
            z-index: 2;
            .plbbw-rhw-image-wrap {
                align-items: center;
                display: flex;
                justify-content: center;
                overflow: hidden;
                padding-bottom: 69.52%; // To keep aspect ratio
                position: relative; // To keep aspect ratio
                width: 100%; // To keep aspect ratio
                .plbbw-rhw-image {
                    height: 100%; // To keep aspect ratio
                    object-fit: cover;
                    object-position: center;
                    position: absolute; // To keep aspect ratio
                    top: 0; // To keep aspect ratio
                    width: 100%; // To keep aspect ratio
                }
            }
        }
        .plbbw-transaction {
            align-items: flex-end;
            background-image: linear-gradient(to top, #000000 -50%, rgba(0, 0, 0, 0) 95%);
            bottom: 0;
            color: white;
            display: flex;
            font-weight: bold;
            height: 100%;
            justify-content: center;
            position: absolute;
            width: 100%;
            z-index: 2;
            &.transaction-1 {
                border-bottom: 5px solid #ff0076;
            }
            &.transaction-2 {
                border-bottom: 5px solid #ffbc00;
            }
        }
        .plbbw-hover {
            background-color: rgba(0, 0, 0, 0.4);
            height: 100%;
            left: 0;
            opacity: 0;
            padding: 10px;
            position: absolute;
            text-align: right;
            top: 0;
            transition: $mb-ease;
            width: 100%;
            z-index: 2;
            &:hover {
                opacity: 1;
            }
        }
    }
    .plbbw-info {
        padding-top: 10px;
        position: relative;
        .plbbw-name {
            @extend .mb-clamp1;
            font-size: 22px;
            font-weight: bold;
        }
        .plbbw-price {
            @extend .mb-clamp1;
            font-size: 15px;
            font-weight: 600;
        }
        .plbbw-area {
            @extend .mb-clamp1;
            font-size: 15px;
            font-weight: 600;
        }
        .plbbw-bookmark {
            bottom: 0;
            position: absolute;
            right: 0;
        }
    }
}
