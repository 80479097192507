.newsletter-form-web {
    .nfw-content {
        padding: 30px;
        .nfw-title {
            color: $mb-color;
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 30px;
            white-space: pre-line;
            text-align: center;
        }
        .hpw-image {
            height: 84px;
            margin: auto;
            margin-bottom: 30px;
        }
    }
    .nfw-toolbar-wrap {
        display: flex;
        justify-content: space-around;
    }
}
