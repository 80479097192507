.not-found-page-web {
    .nfpw-content {
        align-items: center;
        background-position: center;
        background-repeat: repeat;
        background-size: cover;
        display: flex;
        justify-content: center;
        min-height: $nav-height-vh;
        .nfpw-content-wrap {
            align-items: center;
            background: rgba(0, 0, 0, 0.4);
            color: white;
            display: flex;
            flex-direction: column;
            justify-content: center;
            min-height: $nav-height-vh;
            padding: 20px;
            width: 100%;
            .nfpw-title {
                font-size: 45px;
                font-weight: bold;
                padding-bottom: 20px;
            }
            .nfpw-image {
                height: 92px;
            }
            .nfpw-text {
                font-size: 22px;
                font-weight: bold;
                padding: 20px 0;
                text-align: center;
                white-space: pre-line;
            }
        }
    }
}
