.message-list-block-web {
    padding: 30px 0 0;
    .mlbw-time {
        font-size: 11px;
        color: #707070;
        text-align: center;
    }
    .mlbw-body-div {
        display: flex;
        .mlbw-image {
            width: 37px;
            height: 37px;
            object-fit: cover;
            border-radius: 50%;
            margin-right: 20px;
        }
        .mlbw-body-wrap {
            flex: 1;
            .mlbw-name {
                font-size: 15px;
                font-weight: bold;
            }
            .mlbw-body {
                font-size: 15px;
                font-weight: 500;
                white-space: pre-line;
                word-break: break-word;
            }
        }
    }
}
