.phone-number-input-web {
    margin-bottom: 1rem;
    .main-form-label {
        margin-bottom: 7px;
    }
    .PhoneInputInput {
        background: white;
        border: solid 1px #979797;
        min-height: 36px;
        padding: 0px 10px;
    }
}
