.infinite-scroll-helpe-web-empty {
    height: 64px;
    width: 100%;
}

.infinite-scroll-helpe-web-no-more-text {
    height: 64px;
    padding: 20px;
    text-align: center;
}
