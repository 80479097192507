.contact-form-web {
    .cfw-content {
        max-width: 657px;
        padding: 50px 0;
        .cfw-title {
            color: $mb-color;
            font-size: 45px;
            font-weight: bold;
        }
        .cfw-description {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 30px;
        }
        .cfw-input-wrap {
            display: flex;
            margin: 0 -17px;
            @media (max-width: 576px) {
                flex-direction: column;
                margin: 0;
            }
            .select-input-web,
            .text-input-web {
                padding: 0 17px;
                width: 50%;
                @media (max-width: 576px) {
                    padding: 0;
                    width: 100%;
                }
            }
        }
        .mb-button-web .bm-button {
            margin: auto;
        }
    }
}
