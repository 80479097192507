.room-detail-web {
    .rdw-header {
        .rdw-phone {
            margin-right: 20px;
        }
        .rdw-bottom {
            border-bottom: 1px solid grey;
            display: flex;
            margin: auto;
            max-width: 675px;
            padding: 18px 0;
            @media (max-width: 576px) {
                padding: 18px 20px;
            }
            .rdw-image {
                height: 72px;
                margin-right: 26px;
                object-fit: cover;
                width: 108px;
            }
            .rdw-info {
                flex: 1;
                .rdw-title {
                    @extend .mb-clamp1;
                    font-size: 19px;
                    font-weight: bold;
                }
                .rdw-price {
                    @extend .mb-clamp1;
                    font-size: 15px;
                    font-weight: 600;
                }
                .rdw-area {
                    @extend .mb-clamp1;
                    font-size: 15px;
                    font-weight: 600;
                }
            }
        }
    }
    .rdw-content {
        display: flex;
        flex-direction: column-reverse;
        height: calc(100vh - 153px - 109px);
        margin: auto;
        max-width: 675px;
        overflow-y: auto;
        @media (max-width: 576px) {
            padding: 20px;
        }
    }
    .rdw-footer {
        display: flex;
        margin: auto;
        max-width: 675px;
        padding: 20px 0;
        @media (max-width: 576px) {
            padding: 20px;
        }
        .rdw-text-area {
            border-radius: 30px;
            border: solid 2px #707070;
            margin-right: 15px;
        }
        .rdw-send {
            align-items: center;
            display: flex;
            font-size: 25px;
            &.disabled {
                pointer-events: none;
                opacity: 0.4;
            }
        }
    }
}
