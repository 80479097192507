.profile-property-favourite-list-web {
    padding: 60px 0;
    @media (max-width: 767.98px) {
        padding: 40px 0;
    }
    .bravo1 {
        h2 {
            font-size: 16px;
            margin: 30px 0;
        }
    }
}
