.multiple-image-input-web {
    margin-bottom: $form-mb;
    .dropzone {
        align-items: center;
        background: white;
        border-radius: $form-br;
        border: solid 1px #cccccc;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 5px;
        .dz-preview .dz-image {
            border-radius: 0px;
        }
    }
}
