.main-page-web {
    .mpw-content {
        padding-bottom: 70px;
    }
    .mpw-title-text-block {
        padding: 40px 0;
    }
    .mpw-image-text-block {
        display: flex;
        padding: 40px 0;
        @media (max-width: 576px) {
            flex-direction: column;
        }
        .mpw-image-wrap {
            align-items: center;
            display: flex;
            width: 55%;
            @media (max-width: 576px) {
                margin-bottom: 15px;
                width: 100%;
            }
            .mpw-image {
                width: 100%;
            }
        }
        .mpw-text-wrap {
            display: flex;
            flex-direction: column;
            flex: 1;
            justify-content: center;
            padding-left: 60px;
            @media (max-width: 576px) {
                padding-left: 0;
            }
        }
        &.reverse {
            flex-direction: row-reverse;
            @media (max-width: 576px) {
                flex-direction: column;
            }
            .mpw-text-wrap {
                padding-left: 0;
                padding-right: 60px;
                @media (max-width: 576px) {
                    padding-right: 0;
                }
            }
        }
    }
    &.home {
        .banner-section {
            height: $nav-height-vh;
            position: relative;
            width: 100%;
            .banner-cover-image {
                height: 100%;
                object-fit: cover;
                width: 100%;
            }
            .banner-cover-text {
                align-items: center;
                background: rgba(0, 0, 0, 0.35);
                display: flex;
                flex-direction: column;
                height: 100%;
                justify-content: center;
                left: 0;
                position: absolute;
                top: 0;
                width: 100%;
                .banner-text1 {
                    color: white;
                    font-size: 60px;
                    font-weight: bold;
                    line-height: initial;
                    margin-bottom: 30px;
                    text-align: center;
                    @media (max-width: 576px) {
                        font-size: 35px;
                    }
                }
                .banner-text2 {
                    color: white;
                    font-size: 23px;
                    font-weight: bold;
                    margin-bottom: 50px;
                    text-align: center;
                    @media (max-width: 576px) {
                        font-size: 20px;
                        font-weight: 500;
                    }
                }
                .banner-button-wrap {
                    display: flex;
                    justify-content: center;
                }
            }
        }
        .offer-section {
            display: flex;
            padding: 28px 48px;
            @media (max-width: 576px) {
                flex-direction: column;
                padding: 0;
            }
            .offer-item {
                background: $gray1;
                display: flex;
                flex-direction: column;
                justify-content: space-between;
                margin: 0px 15px;
                padding: 30px;
                width: calc(100% / 3);
                @media (max-width: 576px) {
                    margin-bottom: 15px;
                    width: auto;
                }
                .offer-item-text1 {
                    color: #707070;
                    font-size: 15px;
                    font-weight: bold;
                    margin-bottom: 10px;
                    text-transform: uppercase;
                }
                .offer-item-text2 {
                    font-size: 30px;
                    font-weight: bold;
                    margin-bottom: 18px;
                    @media (max-width: 576px) {
                        font-size: 20px;
                    }
                }
                .offer-item-text3 {
                    color: $mb-color;
                    font-size: 15px;
                    font-weight: bold;
                    margin-bottom: 18px;
                    white-space: pre-line;
                }
                .offer-item-image {
                    height: 98px;
                    margin: 0px auto 18px;
                }
                .mb-button-web {
                    justify-content: center;
                }
                &.offer-item-3 {
                    background: $mb-color;
                    .offer-item-text1,
                    .offer-item-text2,
                    .offer-item-text3 {
                        color: white;
                    }
                }
            }
        }
        .map-section {
            .map-block {
                display: flex;
                .map-filters {
                    align-items: center;
                    background: $gray1;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    padding: 20px 56px;
                    @media (max-width: 576px) {
                        width: 100%;
                    }
                    .map-searchbox {
                        border-radius: $form-br;
                        border: 1px solid rgb(204, 204, 204);
                        margin-bottom: $form-mb;
                        padding: 7px 13px;
                        width: 100%;
                    }
                }
                .map {
                    flex: 1;
                    height: 500px;
                    @media (max-width: 576px) {
                        display: none;
                    }
                }
            }
        }
        .property-section {
            .property-list {
                margin: 0 -18px;
            }
        }
        .service-section {
            .block2 {
                padding-bottom: 0;
                .mb-main-title {
                    padding-bottom: 0;
                }
            }
            .mpw-text-wrap {
                &.purchase,
                &.travaux {
                    .mb-main-text {
                        padding-bottom: 20px;
                    }
                }
                &.travaux {
                    .mb-button-web .bw-button {
                        background: #f7d134;
                    }
                }
            }
        }
        .page-section {
            padding-bottom: 50px;
            .image-square-list {
                display: flex;
                margin: 0 -10px;
                @media (max-width: 576px) {
                    flex-direction: column;
                }
                .isl-wrap {
                    width: calc(100% / 3);
                    padding: 0 10px;
                    @media (max-width: 576px) {
                        width: 100%;
                    }
                }
                .isl-image-wrap {
                    display: block;
                    position: relative;
                    .isl-ratio-helper {
                        position: relative;
                        z-index: 2;
                        .isl-rhw-image-wrap {
                            align-items: center;
                            display: flex;
                            justify-content: center;
                            overflow: hidden;
                            padding-bottom: 100%; // To keep aspect ratio
                            position: relative; // To keep aspect ratio
                            width: 100%; // To keep aspect ratio
                            .isl-rhw-image {
                                height: 100%; // To keep aspect ratio
                                object-fit: cover;
                                object-position: center;
                                position: absolute; // To keep aspect ratio
                                top: 0; // To keep aspect ratio
                                width: 100%; // To keep aspect ratio
                            }
                        }
                    }
                }
                .isl-text-wrap {
                    text-align: center;
                    @media (max-width: 576px) {
                        padding-bottom: 40px;
                    }
                    .isl-title {
                        display: block;
                        font-size: 24px;
                        font-weight: bold;
                        padding-top: 10px;
                        @media (max-width: 576px) {
                            font-size: 20px;
                        }
                    }
                }
            }
        }
        .blog-section {
            padding-bottom: 117px;
        }
        .newsletter-section {
            padding-bottom: 119px;
            text-align: center;
            .newsletter-text {
                font-size: 45px;
                font-weight: bold;
                padding-bottom: 30px;
                white-space: pre-line;
                @media (max-width: 576px) {
                    font-size: 30px;
                }
            }
            .newsletter-description {
                font-size: 22px;
                padding-bottom: 30px;
                white-space: pre-line;
            }
            .newsletter-image {
                margin: 0 auto 20px;
            }
            .mb-button-web .bw-button {
                margin: auto;
            }
        }
    }
    &.laboratoire-patisserie {
        .block1 {
            .mpw-image-wrap {
                width: 45%;
            }
        }
        .block2 {
            .mpw-image-wrap {
                width: 45%;
            }
            .title2 {
                padding-top: 30px;
            }
        }
    }
}

.mb-main-title {
    font-size: 35px;
    font-weight: bold;
    padding-bottom: 20px;
    text-align: center;
    white-space: pre-line;
    @media (max-width: 576px) {
        font-size: 24px;
    }
    &.split {
        padding: 0 0 20px 0;
        text-align: initial;
    }
}
h1.mb-main-title {
    line-height: 1.3;
    font-size: 45px;
    @media (max-width: 576px) {
        font-size: 30px;
    }
}
h2.mb-main-title {
    line-height: 1.3;
}
.mb-main-text {
    font-size: 16px;
    text-align: justify;
    white-space: pre-line;
}
