.main-form-label {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    .main-form-label-wrap {
        align-items: baseline;
        color: grey;
        display: flex;
        padding-right: 5px;
    }
    .main-form-helper-wrap {
        align-items: center;
        display: flex;
        .main-form-label-optional {
            color: grey;
            flex-shrink: 0;
            font-size: 10px;
            margin-right: 10px;
        }
        .main-form-label-helper {
            align-items: center;
            cursor: pointer;
            display: flex;
        }
    }
}

.main-form-label-popover-web {
    .popover-content {
        padding: 10px 15px 15px;
        white-space: pre-line;
        .mflpw-header {
            padding-bottom: 10px;
            text-align: center;
            .mflpw-title {
                font-weight: bold;
                text-transform: uppercase;
            }
            .mflpw-optional {
                font-style: italic;
            }
        }
        .mflpw-text {
            font-size: 14px;
        }
    }
}
