.property-list-small-block-web {
    border-top: 1px solid #e5e5e5;
    display: flex;
    padding: 20px 0;
    @media (max-width: 576px) {
        flex-direction: column;
    }
    .plsbw-image-wrap {
        display: block;
        height: 139px;
        position: relative;
        width: 201px;
        @media (max-width: 576px) {
            height: auto;
            width: 100%;
        }
        .plsbw-ratio-helper {
            display: none;
            position: relative;
            z-index: 2;
            @media (max-width: 576px) {
                display: block;
            }
            .plsbw-rhw-image-wrap {
                align-items: center;
                display: flex;
                justify-content: center;
                overflow: hidden;
                padding-bottom: 69.52%; // To keep aspect ratio
                position: relative; // To keep aspect ratio
                width: 100%; // To keep aspect ratio
                .plsbw-rhw-image {
                    height: 100%; // To keep aspect ratio
                    object-fit: cover;
                    object-position: center;
                    position: absolute; // To keep aspect ratio
                    top: 0; // To keep aspect ratio
                    width: 100%; // To keep aspect ratio
                }
            }
        }
        .plsbw-image {
            height: 100%;
            object-fit: cover;
            width: 100%;
            @media (max-width: 576px) {
                display: none;
            }
        }
        .plsbw-transaction {
            align-items: flex-end;
            background-image: linear-gradient(to top, #000000 -50%, rgba(0, 0, 0, 0) 95%);
            bottom: 0;
            color: white;
            display: flex;
            font-weight: bold;
            height: 100%;
            justify-content: center;
            position: absolute;
            width: 100%;
            &.transaction-1 {
                border-bottom: 5px solid #ff0076;
            }
            &.transaction-2 {
                border-bottom: 5px solid #ffbc00;
            }
        }
    }
    .plsbw-info {
        flex: 1;
        padding-left: 26px;
        position: relative;
        @media (max-width: 576px) {
            display: flex;
            padding-left: 0;
            padding-top: 5px;
        }
        .plsbw-name-wrap {
            @media (max-width: 576px) {
                flex: 1;
            }
            .plsbw-name {
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                display: -webkit-box;
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 5px;
                overflow: hidden;
                text-overflow: ellipsis;
            }
            .plsbw-price {
                font-size: 15px;
                font-weight: 600;
            }
            .plsbw-area {
                font-size: 15px;
                font-weight: 600;
            }
        }
        .plsbw-bookmark {
            position: absolute;
            right: 0;
            top: 100px;
            @media (max-width: 576px) {
                position: relative;
                top: initial;
                right: initial;
            }
        }
    }
}
