.room-list-block-web {
    border-bottom: 1px solid #707070;
    display: flex;
    padding: 25px 20px;
    @media (max-width: 576px) {
        padding: 25px 0;
    }
    .rlbw-profile-pic {
        border-radius: 50%;
        height: 87px;
        margin-right: 52px;
        object-fit: cover;
        width: 87px;
        @media (max-width: 576px) {
            height: 50px;
            margin-right: 20px;
            width: 50px;
        }
    }
    .rlbw-profile-nopic {
        align-items: center;
        background: #ffa2a2;
        border-radius: 50%;
        color: white;
        display: flex;
        font-size: 37px;
        font-weight: bold;
        height: 87px;
        justify-content: center;
        margin-right: 52px;
        text-transform: uppercase;
        width: 87px;
        @media (max-width: 576px) {
            height: 50px;
            margin-right: 20px;
            width: 50px;
        }
    }
    .rlbw-wrap {
        display: flex;
        flex: 1;
        @media (max-width: 576px) {
            flex-direction: column;
        }
        .rlbw-center {
            flex: 1;
            .rlbw-name {
                font-size: 22px;
                font-weight: 500;
            }
            .rlbw-property {
                font-size: 15px;
                font-weight: 500;
            }
            .rlbw-message {
                @extend .mb-clamp1;
                color: #707070;
                font-size: 15px;
                font-weight: 500;
            }
        }
        .rlbw-phone {
            font-size: 15px;
            font-weight: 500;
            width: 171px;
        }
    }
}
