.property-list-web {
    .plw-cover {
        height: calc(100vw / 1920 * 761);
        position: relative;
        width: 100%;
        @media (max-width: 576px) {
            height: 60vh;
        }
        .plw-cover-image {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
        .plw-cover-text {
            align-items: flex-start;
            background-image: linear-gradient(to top, #000000 -24%, rgba(0, 0, 0, 0) 95%);
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: flex-end;
            left: 0;
            padding-bottom: 30px;
            position: absolute;
            top: 0;
            width: 100%;
            @media (max-width: 576px) {
                align-items: flex-end;
            }
            .plw-text1 {
                color: white;
                font-size: 60px;
                font-weight: bold;
                margin-bottom: 30px;
                white-space: pre-line;
                @media (max-width: 576px) {
                    font-size: 30px;
                }
            }
            .plw-text2 {
                color: white;
                font-size: 23px;
                font-weight: bold;
                margin-bottom: 30px;
                max-width: 543px;
                white-space: pre-line;
                @media (max-width: 576px) {
                    font-size: 15px;
                    margin-bottom: 20px;
                }
            }
            .plw-button-wrap {
                display: flex;
                @media (max-width: 576px) {
                    display: block;
                }
                .mb-button-web {
                    padding: 10px;
                }
            }
        }
    }
    .plw-filters {
        display: flex;
        padding: 10px;
        @media (max-width: 576px) {
            flex-direction: column;
        }
        .select-input-web {
            margin-bottom: 0;
            padding: 10px;
            width: 25%;
            @media (max-width: 576px) {
                width: 100%;
            }
        }
    }
    .plw-infinite {
        display: flex;
        .plw-list-wrap {
            height: calc(#{$nav-height-vh} - 78px);
            overflow-y: auto;
            padding: 15px;
            width: 40%;
            @media (max-width: 576px) {
                height: auto;
                width: 100%;
                &.is-mobile-map {
                    display: none;
                }
            }
        }
        .plw-map-wrap {
            flex: 1;
            height: calc(#{$nav-height-vh} - 78px);
            overflow: hidden;
            @media (max-width: 576px) {
                display: none;
                &.is-mobile-map {
                    display: block;
                }
            }
        }
    }
    .plw-footer-wrap {
        backdrop-filter: saturate(180%) blur(20px);
        bottom: 0;
        display: none;
        padding: 10px;
        position: fixed;
        width: 100%;
        z-index: 999;
        @media (max-width: 576px) {
            display: block;
        }
        .plw-footer {
            align-items: center;
            background: #e5e5e5;
            border-radius: 50px;
            display: flex;
            justify-content: center;
            margin: 0 20px;
            padding: 10px;
            .plw-footer-img {
                height: 35px;
                margin-right: 15px;
            }
        }
    }
}

.mb-leaflet {
    .leaflet-container {
        background: white;
        height: 100%;
        width: 100%;
        a {
            color: initial;
            text-decoration: initial;
        }
        .tlcw-map-searchbox {
            border: 2px solid rgba(0, 0, 0, 0.2);
            .search-control {
                border-radius: 2px;
                .search-control-icon-button {
                    width: 30px;
                }
            }
        }
        .leaflet-popup-content {
            margin: 0;
            p {
                margin: 0;
            }
        }
        a.leaflet-popup-close-button {
            color: white;
        }
    }
}
