.mb-footer-web {
    background: black;
    color: white;
    display: flex;
    padding: 90px 100px;
    @media (max-width: 576px) {
        flex-direction: column;
        padding: 20px;
    }
    .fw-block-1 {
        padding: 0 15px;
        width: 40%;
        @media (max-width: 576px) {
            padding: 0 0 20px;
            width: 100%;
        }
        .fw-text {
            font-size: 15px;
            margin-bottom: 10px;
        }
        .fw-newsletter-action {
            color: $mb-color;
            cursor: pointer;
            display: inline-block;
            margin-bottom: 30px;
        }
        .fw-social-links {
            display: flex;
            .fw-social-image {
                margin-right: 50px;
                width: 30px;
            }
        }
    }
    .fw-block-2 {
        padding: 0 15px;
        width: 20%;
        @media (max-width: 576px) {
            padding: 0 0 20px;
            width: 100%;
        }
    }
    .fw-block-3 {
        padding: 0 15px;
        width: 20%;
        @media (max-width: 576px) {
            padding: 0 0 20px;
            width: 100%;
        }
    }
    .fw-block-4 {
        padding: 0 15px;
        width: 20%;
        @media (max-width: 576px) {
            padding: 0;
            width: 100%;
        }
    }
    .fw-title {
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 15px;
        text-transform: uppercase;
    }
    .fw-link {
        display: block;
        font-size: 15px;
        margin-bottom: 7px;
        transition: $mb-ease;
        .active,
        &:hover {
            color: $mb-color;
        }
    }
}
