.profile-form-web {
    padding: 20px 0;
    .prfw-input-wrap {
        display: flex;
        margin: 0 -10px;
        .phone-number-input-web,
        .text-input-web {
            padding: 0 10px;
            width: 50%;
        }
    }
    .prfw-wrap {
        align-items: center;
        background: white;
        display: flex;
        justify-content: flex-end;
        margin: auto;
        .mb-button-web .bw-button {
            padding: 10px 35px;
        }
    }
}
