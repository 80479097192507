.property-form-web {
    .pfw-header {
        align-items: center;
        display: flex;
        height: 80px;
        padding: 10px 49px;
        position: relative;
        @media (max-width: 576px) {
            height: auto;
            padding: 10px;
        }
        .pfw-text {
            color: $mb-color;
            font-size: 22px;
            padding-bottom: 10px;
            @media (max-width: 576px) {
                font-size: 16px;
            }
        }
        .pfw-border {
            background: #b1afaf;
            bottom: 0;
            height: 10px;
            left: 0;
            position: absolute;
            width: 100%;
            .pfw-progress {
                background: $mb-color;
                height: 100%;
                transition: $mb-ease;
                width: 0%;
                &.adresse {
                    width: 25%;
                }
                &.info {
                    width: 50%;
                }
                &.images {
                    width: 75%;
                }
                &.preview {
                    width: 100%;
                }
            }
        }
    }
    .pfw-content {
        background: $gray1;
        height: 100%;
        overflow-y: auto;
        .pfw-panel {
            background: white;
            background: linear-gradient(to right, white 0%, white 55%, $gray1 55%, $gray1 100%);
            min-height: 100%;
            // width: 847px;
            @media (max-width: 576px) {
                background: white;
                display: flex;
                flex-direction: column;
            }
            .main-form-label {
                justify-content: flex-start;
                .main-form-label-wrap {
                    color: black;
                    flex-shrink: 0;
                    font-size: 22px;
                    margin-bottom: 16px;
                    width: calc(55vw - 162px - 162px);
                    @media (max-width: 576px) {
                        font-size: 16px;
                        flex: 1;
                        margin-bottom: 0;
                    }
                }
                .main-form-helper-wrap {
                    color: #989898;
                    padding-left: 182px;
                    @media (max-width: 576px) {
                        padding-left: 0;
                    }
                }
                .mflw-helper {
                    display: none;
                    @media (max-width: 576px) {
                        display: block;
                    }
                }
            }
            .form-input-wrap {
                display: flex;
                .fiw-input {
                    flex-shrink: 0;
                    width: calc(55vw - 162px - 162px);
                    @media (max-width: 576px) {
                        width: 100%;
                    }
                }
                .fiw-helper {
                    color: #989898;
                    padding-left: 182px;
                    @media (max-width: 576px) {
                        display: none;
                    }
                }
            }
            .pfw-inline-list,
            .multiple-image-input-web {
                flex-shrink: 0;
                width: calc(55vw - 162px - 162px);
                @media (max-width: 576px) {
                    width: 100%;
                }
            }
        }
    }
    .pfw-footer {
        background: $gray1;
        .pfw-wrap {
            align-items: center;
            background: white;
            display: flex;
            justify-content: space-between;
            padding: 5px 15px;
            width: 55%;
            @media (max-width: 576px) {
                background: $gray1;
                width: 100%;
            }
            .pfw-back {
                cursor: pointer;
                display: block;
                font-size: 22px;
                transition: $mb-ease;
                @media (max-width: 576px) {
                    font-size: 10px;
                }
                .fa {
                    margin-right: 10px;
                }
                &:hover {
                    color: $mb-color;
                }
            }
            .mb-button-web .bw-button {
                padding: 10px 35px;
                @media (max-width: 576px) {
                    padding: 10px;
                }
            }
            .pfw-button-wrap {
                display: flex;
                .mb-button-web {
                    padding-left: 20px;
                }
            }
        }
    }
}
