.article-list-block-web {
    display: inline-block;
    padding: 20px 18px 75px;
    width: 50%;
    @media (max-width: 576px) {
        width: 100%;
    }
    .albw-image {
        width: 100%;
    }
    .albw-name {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 26px;
        @media (max-width: 576px) {
            display: block;
            font-size: 20px;
            margin-bottom: 5px;
        }
    }
    .albw-abstract {
        font-size: 15px;
        padding: 26px 0;
        white-space: pre-line;
        -webkit-line-clamp: 3;
        @media (max-width: 576px) {
            padding-top: 5px;
        }
    }
    .albw-button {
        color: $mb-color;
        font-size: 24px;
        font-weight: bold;
        @media (max-width: 576px) {
            font-size: 16px;
        }
    }
}
