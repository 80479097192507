.partner-page-web {
    .ppw-cover {
        height: calc(100vw / 1920 * 686);
        position: relative;
        width: 100%;
        @media (max-width: 576px) {
            height: 60vh;
        }
        .ppw-cover-image {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
        .ppw-cover-text {
            align-items: flex-start;
            background-image: linear-gradient(to top, #000000 -24%, rgba(0, 0, 0, 0) 95%);
            display: flex;
            flex-direction: column;
            height: 100%;
            justify-content: flex-end;
            left: 0;
            position: absolute;
            top: 0;
            width: 100%;
            @media (max-width: 576px) {
                align-items: flex-end;
            }
            .ppw-text1 {
                color: white;
                font-size: 60px;
                font-weight: bold;
                margin-bottom: 30px;
                @media (max-width: 576px) {
                    font-size: 30px;
                }
            }
            .ppw-text2 {
                color: white;
                font-size: 23px;
                font-weight: bold;
                margin-bottom: 135px;
                @media (max-width: 576px) {
                    font-size: 15px;
                    margin-bottom: 20px;
                }
            }
        }
    }
    .ppw-head-block {
        display: flex;
        padding: 102px 0 96px;
        @media (max-width: 576px) {
            flex-direction: column;
            padding: 50px 0 96px;
        }
        .ppw-head-image-wrap {
            width: 40%;
            @media (max-width: 576px) {
                width: 100%;
            }
            .ppw-head-image {
                height: 109px;
                @media (max-width: 576px) {
                    margin: auto;
                    height: 60px;
                }
            }
        }
        .ppw-head-text {
            padding-left: 20px;
            width: 60%;
            @media (max-width: 576px) {
                width: 100%;
            }
            .ppw-head-name {
                font-size: 23px;
                font-weight: bold;
                padding-bottom: 30px;
                white-space: pre-line;
                @media (max-width: 576px) {
                    padding-top: 20px;
                    font-size: 20px;
                }
            }
        }
    }
    .ppw-service-block {
        display: flex;
        padding-bottom: 96px;
        @media (max-width: 576px) {
            flex-direction: column;
        }
        .ppw-service-image {
            width: 50%;
            @media (max-width: 576px) {
                width: 100%;
            }
        }
        .ppw-service-text {
            padding-left: 146px;
            width: 50%;
            @media (max-width: 576px) {
                padding-left: 0;
                width: 100%;
            }
            .ppw-service-name {
                font-size: 45px;
                font-weight: bold;
                padding-bottom: 37px;
                @media (max-width: 576px) {
                    font-size: 30px;
                    padding-bottom: 10px;
                }
            }
            .ppw-service-description {
                font-size: 15px;
                white-space: pre-line;
            }
        }
        &.ppw-service-block2 {
            flex-direction: row-reverse;
            @media (max-width: 576px) {
                flex-direction: column;
            }
            .ppw-service-text {
                padding-left: 0;
                padding-right: 146px;
                @media (max-width: 576px) {
                    padding-right: 0;
                    width: 100%;
                }
            }
        }
    }
    .ppw-newsletter-wrap {
        padding-bottom: 119px;
        text-align: center;
        .ppw-text {
            font-size: 45px;
            font-weight: bold;
            padding-bottom: 30px;
            white-space: pre-line;
            @media (max-width: 576px) {
                font-size: 30px;
            }
        }
        .ppw-blog-image {
            height: 84px;
            margin: 0 auto 20px;
        }
        .mb-button-web .bw-button {
            margin: auto;
        }
    }
}
