.mb-button-web {
    align-items: center;
    display: flex;
    padding: 10px 0;
    .bw-button {
        background: $mb-color;
        border-radius: 36px;
        color: white;
        cursor: pointer;
        font-weight: bold;
        padding: 15px 35px;
        text-align: center;
        transition: $mb-ease;
        &:hover {
            opacity: 0.7;
        }
    }
    &.disabled {
        opacity: 0.3;
        pointer-events: none;
    }
    &.reverse {
        .bw-button {
            background: white;
            color: $mb-color;
        }
    }
}
