.property-list-medium-block-web {
    border-top: 1px solid #e5e5e5;
    display: flex;
    padding: 20px 18px 20px 0;
    @media (max-width: 576px) {
        flex-direction: column;
        padding: 20px 0;
    }
    .plmbw-image-wrap {
        display: block;
        height: 193px;
        position: relative;
        width: 298px;
        @media (max-width: 576px) {
            width: 100%;
        }
        .plmbw-image {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
        .plmbw-transaction {
            align-items: flex-end;
            background-image: linear-gradient(to top, #000000 -50%, rgba(0, 0, 0, 0) 95%);
            bottom: 0;
            color: white;
            display: flex;
            font-weight: bold;
            height: 100%;
            justify-content: center;
            position: absolute;
            width: 100%;
            &.transaction-1 {
                border-bottom: 5px solid #ff0076;
            }
            &.transaction-2 {
                border-bottom: 5px solid #ffbc00;
            }
        }
    }
    .plmbw-info {
        flex: 1;
        padding: 0 26px;
        position: relative;
        @media (max-width: 576px) {
            padding: 0;
        }
        .plmbw-status-text {
            color: red;
            &.status-v {
                color: green;
            }
        }
        .plmbw-name {
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 3;
            display: -webkit-box;
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 5px;
            overflow: hidden;
            text-overflow: ellipsis;
        }
        .plmbw-price {
            font-size: 15px;
            font-weight: 600;
        }
        .plmbw-area {
            font-size: 15px;
            font-weight: 600;
        }
        .plmbw-bookmark {
            position: absolute;
            left: 0;
            bottom: 0;
            display: flex;
            @media (max-width: 576px) {
                justify-content: space-around;
                position: relative;
            }
            .mb-button-web {
                padding: 5px;
                .bw-button {
                    padding: 5px 30px;
                }
            }
        }
    }
}
