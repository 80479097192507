.google-login-block-web {
    margin: 0 auto 25px;
    padding-top: 20px;
    text-align: center;
    width: 300px;
    &.apple {
        padding-top: 40px;
    }
    .gl-error {
        color: $error;
        display: block;
        margin-bottom: 0;
        margin-top: 0.25rem;
        text-align: left;
        white-space: pre-line;
        width: 100%;
    }
}
