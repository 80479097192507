:root {
    --ion-font-family: "Raleway", sans-serif;
    ion-input {
        --padding-end: 13px;
        --padding-start: 13px;
    }
    ion-textarea {
        --padding-end: 13px;
        --padding-start: 13px;
    }
    ion-select {
        --padding-end: 13px;
        --padding-start: 13px;
    }
}

.body-web {
    background-color: white;
    color: black;
    font-family: "Raleway", sans-serif;
    font-size: 16px;
    font-weight: normal;
    margin: 0;
    a {
        color: inherit;
        text-decoration: none;
    }
    img {
        display: block;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    p {
        line-height: 1.5;
        margin: 0;
    }
    .form-footer {
        padding: 0 15px;
    }
    .hidden-mobile {
        @media (max-width: 576px) {
            display: none;
        }
    }
    .mb-clamp1 {
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 1;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .mb-container {
        margin-left: auto;
        margin-right: auto;
        padding-left: 15px;
        padding-right: 15px;
        width: 100%;
        @media (min-width: 576px) {
            max-width: 540px;
        }
        @media (min-width: 768px) {
            max-width: 720px;
        }
        @media (min-width: 992px) {
            max-width: 960px;
        }
        @media (min-width: 1200px) {
            max-width: 1140px;
        }
    }
    .mb-hidden-horizontal-scrollbar {
        -ms-overflow-style: none; /* IE 10+ */
        -webkit-overflow-scrolling: touch;
        overflow-x: auto;
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            /* WebKit */
            height: 0;
            width: 0;
        }
    }
    .mb-hidden-vertical-scrollbar {
        -ms-overflow-style: none; /* IE 10+ */
        -webkit-overflow-scrolling: touch;
        overflow-y: auto;
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            /* WebKit */
            height: 0;
            width: 0;
        }
    }
    .navbar-pt {
        padding-top: $nav-height;
    }
    .pre-line {
        white-space: pre-line;
    }
}
