.property-list-map-block-web {
    border-radius: 12px 12px 0 0;
    border-top: 1px solid #e5e5e5;
    display: block;
    font-family: "Raleway", sans-serif;
    overflow: hidden;
    .plmbw-image-wrap {
        height: 139px;
        position: relative;
        width: 300px;
        .plmbw-image {
            height: 100%;
            object-fit: cover;
            width: 100%;
        }
        .plmbw-transaction {
            align-items: flex-end;
            background: rgba(0, 0, 0, 0.5);
            bottom: 0;
            color: white;
            display: flex;
            font-weight: bold;
            height: 100%;
            justify-content: center;
            position: absolute;
            width: 100%;
            &.transaction-1 {
                border-bottom: 5px solid #ff0076;
            }
            &.transaction-2 {
                border-bottom: 5px solid #ffbc00;
            }
        }
    }
    .plmbw-info {
        flex: 1;
        position: relative;
        padding: 0 5px;
        .plmbw-name {
            font-size: 14px;
            font-weight: bold;
        }
        .plmbw-footer {
            align-items: flex-end;
            display: flex;
            justify-content: space-between;
            .plmbw-info-wrap {
                font-size: 13px;
            }
            .plmbw-bookmark {
                margin-right: -13px;
            }
        }
    }
}
