// To check
.geosuggest {
    font-size: 19px;
    font-size: 1rem;
    position: relative;
    margin: 1em auto;
    text-align: left;
    width: 100%;
    margin: 0 0 25px;
}
.geosuggest__input {
    -webkit-tap-highlight-color: transparent;
    background-color: white;
    border: 0;
    border-bottom: 1px solid #4d4d4d;
    box-sizing: content-box;
    color: currentColor;
    display: block;
    flex-grow: 1;
    margin: 0;
    min-width: 0;
    padding: 6px 0 7px;
    width: 100%;
}
.geosuggest__suggests {
    -webkit-overflow-scrolling: touch;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    max-height: 25em;
    padding: 0;
    margin-top: -1px;
    background: #fff;
    border: 2px solid #267dc0;
    border-top-width: 0;
    overflow-x: hidden;
    overflow-y: auto;
    list-style: none;
    z-index: 5;
    transition: $mb-ease;
}
.geosuggest__suggests--hidden {
    max-height: 0;
    overflow: hidden;
    border-width: 0;
}

/**
* A geosuggest item
*/
.geosuggest__item {
    font-size: 19px;
    font-size: 1rem;
    padding: 0.5em 0.65em;
    cursor: pointer;
}
.geosuggest__item:hover,
.geosuggest__item:focus {
    background: #f5f5f5;
}
.geosuggest__item--active {
    background: #267dc0;
    color: #fff;
}
.geosuggest__item--active:hover,
.geosuggest__item--active:focus {
    background: #ccc;
}
.geosuggest__item__matched-text {
    font-weight: bold;
}

//--- Start location dropdown design ---//
.geo-suggest-label {
    font-size: 13px;
    font-weight: 500;
    margin-bottom: 8px;
}
.geosuggest {
    margin-bottom: 0;
}
.geosuggest__input {
    background: white;
    border-radius: $form-br;
    border: 1px solid #cccccc;
    box-sizing: border-box;
    display: block;
    font-size: 14px;
    font-weight: 300;
    height: 36px;
    padding: 0 10px;
    width: 100%;
    &:focus {
        outline: none;
    }
}
.geosuggest__suggests {
    background: #141418;
    border: none;
    box-shadow: 2px 6px 21px -2px rgba(0, 0, 0, 0.75);
    &.geosuggest__suggests--hidden {
        border: 0;
    }
    .geosuggest__item {
        border-bottom: 1px solid black;
        color: rgba(white, 1) !important;
        font-size: 14px;
        font-weight: 300;
        transition: $mb-ease;
        &.geosuggest__item--active {
            background: #151414;
            color: $mb-color !important;
            display: block !important;
        }
        &:hover {
            background: #151414;
            color: $mb-color !important;
            display: block !important;
        }
        .geosuggest__item__matched-text {
            font-weight: 300;
        }
    }
}
// For safari
.geosuggest__input-wrapper {
    ::-webkit-input-placeholder {
        /* Edge */
        color: transparent;
    }
    :-ms-input-placeholder {
        /* Internet Explorer */
        color: transparent;
    }
    ::placeholder {
        color: transparent;
    }
}

.total-location-chips {
    display: inline-block;
    padding: 10px 0 0;
    .main-chip {
        .main-avatar {
            color: #616161;
            font-size: 1rem;
            height: 22px;
            margin-right: 0;
            width: 22px;
            img {
                border-radius: 50%;
                padding: 2px;
            }
        }
        span {
            white-space: initial;
        }
        svg {
            color: white;
            height: 16px;
        }
    }
}
