.icon-block-web {
    --ion-color-base: transparent !important;
    --ion-color-tint: transparent !important;
    display: inline-block;
    height: auto;
    transition: $mb-ease;
    .ibw-icon {
        color: black;
        display: inline-block;
        font-size: 25px;
        padding: 6px 0px;
        &.fa-star,
        &.fa-star-o {
            color: $mb-color;
        }
    }
    &:hover {
        opacity: 0.7;
    }
}
