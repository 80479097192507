.article-detail-block-web {
    padding: 80px 0;
    @media (max-width: 576px) {
        padding: 20px 0;
    }
    .adbw-back {
        display: block;
        font-size: 22px;
        padding-bottom: 20px;
        transition: $mb-ease;
        @media (max-width: 576px) {
            font-size: 16px;
        }
        &:hover {
            color: $mb-color;
        }
    }
    .adbw-name {
        font-size: 60px;
        font-weight: bold;
        padding-bottom: 87px;
        @media (max-width: 576px) {
            font-size: 25px;
            padding-bottom: 30px;
        }
    }
}
