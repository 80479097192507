.image-crop-input-web {
    display: flex;
    margin-bottom: $form-mb;
    .main-form-label {
        margin-bottom: 7px;
    }
    .iciw-dropzone-input-web {
        align-items: center;
        background: #d6d4d4;
        border-radius: 50%;
        cursor: pointer;
        display: flex;
        justify-content: center;
        overflow: hidden;
        width: 87px;
        .iciw-input {
            align-items: center;
            display: flex;
            flex-direction: column;
            height: 87px;
            justify-content: center;
            padding: 20px;
            text-align: center;
            .m-icon {
                font-size: 23px;
                height: 24px;
                position: relative;
                z-index: 2;
            }
        }
        .iciw-preview-img {
            width: 100%;
        }
    }
    .dropzone {
        align-items: center;
        background: grey;
        border-radius: $form-br;
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
        padding: 5px;
        .dz-message {
            margin: 0;
        }
        .dz-preview .dz-image {
            border-radius: 0px;
        }
    }
    .iciw-image-wrap {
        .iciw-image {
            margin: auto;
            object-fit: cover;
            &.profile-pic {
                border-radius: 50%;
                height: 87px;
                width: 87px;
            }
        }
    }
    .iciw-buttons {
        .iciw-dropzone-input-web {
            background: none;
            .iciw-button-link {
                display: block;
                margin-bottom: 10px;
                cursor: pointer;
                &:hover {
                    color: $mb-color;
                }
            }
        }
    }
}
