.property-detail-block-web {
    .pdbw-content-wrap {
        display: flex;
        @media (max-width: 576px) {
            flex-direction: column;
        }
        .pdbw-content {
            padding-top: 28px;
            // height: $nav-height-vh;
            // overflow-x: hidden;
            // overflow-y: auto;
            padding-bottom: 159px;
            width: calc(100% - 402px);
            @media (max-width: 576px) {
                padding-bottom: 20px;
                width: 100%;
            }
            .pdbw-back {
                display: inline-block;
                font-size: 22px;
                padding-bottom: 63px;
                transition: $mb-ease;
                @media (max-width: 576px) {
                    font-size: 16px;
                    padding-bottom: 20px;
                }
                &:hover {
                    color: $mb-color;
                }
            }
            .pdbw-location {
                font-size: 24px;
                font-weight: bold;
                padding-bottom: 18px;
                @media (max-width: 576px) {
                    font-size: 16px;
                }
            }
            .pdbw-name {
                font-size: 38px;
                @media (max-width: 576px) {
                    font-size: 25px;
                }
            }
            .pdbw-slider {
                padding: 80px 0;
                @media (max-width: 576px) {
                    padding: 30px 0 100px;
                }
                .pdbw-slide {
                    align-items: center;
                    display: flex;
                    height: 400px;
                    @media (max-width: 767.98px) {
                        height: 250px;
                    }
                    img {
                        margin: auto;
                        max-height: 100%;
                        max-width: 100%;
                    }
                }
                .pdbw-thumbs {
                    bottom: -60px;
                    li {
                        height: 45px;
                        width: 60px;
                        .pdbw-thumb {
                            cursor: pointer;
                            height: 100%;
                            object-fit: cover;
                            width: 100%;
                        }
                    }
                }
            }
            .pdbw-title {
                font-size: 45px;
                font-weight: bold;
                padding-bottom: 50px;
                @media (max-width: 576px) {
                    font-size: 25px;
                    padding-bottom: 20px;
                }
                &.localisation {
                    padding-bottom: 10px;
                }
            }
            .pdbw-transport {
                padding-bottom: 50px;
            }
            .pdbw-description {
                font-size: 22px;
                font-weight: bold;
                padding-bottom: 86px;
                white-space: pre-line;
                @media (max-width: 576px) {
                    font-size: 16px;
                }
            }
            .pdbw-surface-wrap {
                padding-bottom: 86px;
                .pdbw-surface-header {
                    display: flex;
                    padding-bottom: 45px;
                    .pdbw-surface-header-1,
                    .pdbw-surface-header-2,
                    .pdbw-surface-header-3 {
                        font-size: 22px;
                        font-weight: bold;
                        padding-right: 5px;
                        width: calc(100% / 3);
                        @media (max-width: 576px) {
                            font-size: 16px;
                        }
                    }
                    @media (max-width: 576px) {
                        padding-bottom: 15px;
                    }
                }
                .pdbw-surface-block {
                    display: flex;
                    .pdbw-surface-block-1,
                    .pdbw-surface-block-2,
                    .pdbw-surface-block-3 {
                        font-size: 15px;
                        padding-right: 5px;
                        width: calc(100% / 3);
                        @media (max-width: 576px) {
                            font-size: 12px;
                        }
                    }
                }
                .pdbw-surface-footer {
                    display: flex;
                    padding-top: 45px;
                    .pdbw-surface-footer-1 {
                        align-items: center;
                        display: flex;
                        font-size: 15px;
                        text-transform: uppercase;
                        width: calc(100% / 3 * 2);
                    }
                    .pdbw-surface-footer-2 {
                        align-items: center;
                        display: flex;
                        font-size: 22px;
                        font-weight: bold;
                        width: calc(100% / 3);
                    }
                }
            }
            .pdbw-prestations {
                padding-bottom: 86px;
                div {
                    display: inline-block;
                    padding-right: 5px;
                    width: calc(100% / 3);
                    p {
                        font-size: 15px;
                        font-weight: bold;
                        @media (max-width: 576px) {
                            font-size: 12px;
                        }
                        &.not-checked {
                            color: #b1afaf;
                            font-weight: normal;
                            text-decoration: line-through;
                        }
                    }
                }
            }
            .pdbw-tarif-wrap {
                padding-bottom: 86px;
                .pdbw-tarif-block {
                    display: flex;
                    @media (max-width: 576px) {
                        justify-content: space-between;
                    }
                    .pdbw-tarif-block-1 {
                        align-items: center;
                        display: flex;
                        font-size: 15px;
                        font-weight: 600;
                        width: calc(100% / 3);
                        @media (max-width: 576px) {
                            width: auto;
                        }
                    }
                    .pdbw-tarif-block-2 {
                        align-items: center;
                        display: flex;
                        font-size: 22px;
                        font-weight: bold;
                        width: calc(100% / 3 * 2);
                        @media (max-width: 576px) {
                            width: auto;
                        }
                    }
                }
            }
            .pdbw-map {
                height: 329px;
                width: 100%;
            }
        }
        .pdbw-form {
            flex: 1;
            padding-left: 30px;
            @media (max-width: 576px) {
                padding-left: 0;
            }
            .pdbw-form-sticky {
                position: sticky;
                padding-top: 28px;
                top: 0;
                .pdbw-form-wrap {
                    background: $gray1;
                    padding: 26px;
                    .pdbw-form-title {
                        color: $mb-color;
                        font-size: 22px;
                        font-weight: bold;
                        padding-bottom: 22px;
                    }
                    .pdbw-room-title {
                        font-weight: bold;
                    }
                    .pdbw-room-date {
                        color: #707070;
                        font-size: 14px;
                        padding-bottom: 10px;
                    }
                    .pdbw-room-body {
                        font-size: 15px;
                        padding-bottom: 20px;
                        white-space: pre-line;
                    }
                    .pdbw-room-link {
                        font-size: 14px;
                        &:hover {
                            color: $mb-color;
                        }
                    }
                    .mb-button-web .bw-button {
                        background: transparent;
                        border: solid 2px #707070;
                        color: #707070;
                    }
                }
                .pdbw-bookmark {
                    padding-top: 20px;
                }
            }
        }
    }
    .pdbw-contact-wrap {
        padding-bottom: 119px;
        text-align: center;
        .pdbw-text {
            font-size: 45px;
            font-weight: bold;
            padding-bottom: 30px;
            white-space: pre-line;
            @media (max-width: 576px) {
                font-size: 30px;
            }
        }
        .pdbw-contact-image {
            margin: 0 auto 20px;
        }
        .mb-button-web .bw-button {
            margin: auto;
        }
    }
    &.preview {
        background: $gray1;
        padding: 0;
        .pdbw-content-wrap {
            background: white;
            margin: 0;
            width: 55%;
        @media (max-width: 576px) {
            width: 100%;
        }
            .pdbw-content {
                height: auto;
                width: 100%;
                .pdbw-back {
                    display: none;
                }
            }
            .pdbw-form {
                display: none;
            }
        }
        .pdbw-contact-wrap {
            display: none;
        }
    }
}
