.login-block-web {
    background: $gray1;
    padding: 44px 20px;
    .lbw-title {
        color: $mb-color;
        font-size: 30px;
        font-weight: bold;
    }
    .lbw-separation {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;
        .lbw-separation-line {
            width: 40%;
            hr {
                border-top: 1px solid white;
                border: 0;
                display: block;
                height: 1px;
                margin: 1em 0;
                padding: 0;
            }
        }
    }
    .mb-button-web .bw-button {
        margin: auto;
    }
    .lbw-activation-text {
        font-size: 20px;
        padding: 20px;
    }
    .lbw-password-reset {
        font-size: 12px;
        margin-top: 20px;
    }
    .lbw-terms {
        margin-top: 20px;
        display: flex;
        .lbw-terms-text {
            margin-left: 10px;
            flex: 1;
        }
    }
}
