.profile-detail-web {
    .pdw-content {
        display: flex;
        min-height: $nav-foo-height-vh;
        @media (max-width: 576px) {
            flex-direction: column;
            min-height: auto;
        }
        .pdw-left {
            padding: 0 50px;
            width: 50%;
            @media (max-width: 576px) {
                padding: 0;
                width: 100%;
            }
        }
        .pdw-right {
            padding: 0 50px;
            width: 50%;
            @media (max-width: 576px) {
                padding: 0;
                width: 100%;
            }
            .pdw-settings {
                padding-top: 20px;
                .pdw-settings-item {
                    padding-bottom: 15px;
                    .pdw-settings-text {
                        padding-left: 20px;
                        font-size: 12px;
                        flex: 1;
                    }
                }
                .pdw-link {
                    display: inline-block;
                    font-size: 12px;
                    text-decoration: underline;
                    margin-top: 15px;
                }
            }
        }
        .pdw-title-wrap {
            align-items: center;
            border-bottom: 1px solid black;
            display: flex;
            padding: 30px 0 10px;
            .pdw-title {
                color: $mb-color;
                flex: 1;
                font-size: 20px;
                font-weight: bold;
            }
        }
    }
}
